var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
// @ts-ignore-next-line
import { Animated } from "react-native";
import React, { useRef, useCallback, useContext, } from "react";
import { easeOutBack } from "../Components";
import { useFocusEffect, useNavigation } from "@react-navigation/native";
import { ThemeContext } from "../Theme";
// This animates in its children when the user navigates to a new screen
// They will simultaniously slide up into position and fade in
// They will also fade out when the user navigates away
export var AnimatedScreen = function (_a) {
    var children = _a.children, _b = _a.fadeOut, fadeOut = _b === void 0 ? false : _b, _c = _a.fadeIn, fadeIn = _c === void 0 ? true : _c;
    var theme = useContext(ThemeContext);
    var animatedTop = useRef(new Animated.Value(fadeIn ? theme.screenAnimationY : 0)).current;
    var animatedOpacity = useRef(new Animated.Value(fadeIn ? 0 : 1)).current;
    var navigation = useNavigation();
    // animate out the screen when the user navigates somewhere else
    React.useEffect(function () {
        if (fadeOut) {
            // @ts-ignore-next-line
            navigation.setParams({ fadeOut: false });
            // fade out
            Animated.timing(animatedOpacity, {
                toValue: 0,
                duration: theme.screenAnimationOutSpeed,
                useNativeDriver: false,
            }).start();
        }
    }, [fadeOut]);
    //animate in the screen when the user navigates to it
    useFocusEffect(useCallback(function () {
        if (fadeIn) {
            // fade in
            Animated.parallel([
                Animated.timing(animatedOpacity, {
                    toValue: 1,
                    duration: theme.screenAnimationSpeed,
                    useNativeDriver: false,
                }),
                // slide up
                Animated.timing(animatedTop, {
                    toValue: 0,
                    easing: easeOutBack,
                    duration: theme.screenAnimationSpeed,
                    useNativeDriver: false,
                }),
            ]).start();
        }
    }, []));
    return (_jsx(Animated.View, __assign({ style: { flex: 1, top: animatedTop, opacity: animatedOpacity } }, { children: children })));
};
