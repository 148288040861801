var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { StyleSheet, Dimensions, } from "react-native";
import React, { createContext } from "react";
import white_menu from "./assets/menu_white.png";
import black_menu from "./assets/menu_black.png";
import { useState } from "react";
import { useEffect } from "react";
// helper function to clamp a value between a min and max
var clamp = function (min, max, val) {
    return Math.round(Math.min(Math.max(val, min), max));
};
// theme-independent basic layout styling
export var styles = StyleSheet.create({
    flex: {
        flex: 1,
    },
    justifyCenter: {
        justifyContent: "center",
    },
    alignCenter: {
        alignItems: "center",
    },
    wrap: {
        flexWrap: "wrap",
    },
    row: {
        flexDirection: "row",
    },
    reverseRow: {
        flexDirection: "row-reverse",
    },
    reverseColumn: {
        flexDirection: "column-reverse",
    },
    slim: {
        width: "100%",
        maxWidth: 1500,
        paddingHorizontal: 50,
    },
    fullWidth: {
        width: "100%",
    },
    centered: {
        alignItems: "center",
        justifyContent: "center",
    },
});
// various properties that most themes will have in common, mostly things like component sizing/spacing/positioning
var defaultTheme = function (scale, smallerDimension) { return ({
    header: {
        fontSize: clamp(45, 70, 100 * scale),
        fontWeight: "bold",
    },
    body: {
        fontSize: clamp(30, 45, 75 * scale),
    },
    caption: {
        fontSize: clamp(20, 30, 45 * scale),
    },
    buttonText: {
        fontSize: clamp(14, 16, 30 * scale),
        paddingBottom: 2,
    },
    phoneHeight: clamp(smallerDimension === "height" ? 550 : 750, 1232, smallerDimension === "height" ? 1100 * scale : 1300 * scale),
    phoneScaleInitial: 1.25,
    phoneScaleFinal: 0.65,
    appScaleInitial: 0.5,
    appCycleTime: 6,
    largeSpace: clamp(50, 100, 150 * scale),
    mediumSpace: clamp(25, 50, 75 * scale),
    mediumSmallSpace: clamp(17, 35, 55 * scale),
    smallSpace: clamp(8, 15, 25 * scale),
    messageHeightHolder: clamp(50, 100, 150 * scale),
    screenAnimationY: clamp(150, 200, 300 * scale),
    screenAnimationSpeed: 850,
    screenAnimationOutSpeed: 250,
    showcaseImageLong: clamp(250, 400, 600 * scale),
    showcaseImageShort: clamp(125, 200, 300 * scale),
    showcaseTextWidth: clamp(200, 500, 700 * scale),
    appLinkSize: clamp(40, 70, 100 * scale),
    webLinkHeight: clamp(30, 50, 75 * scale),
    webLinkWidth: clamp(60, 100, 150 * scale),
    linearGradient: {
        useAngle: true,
        angle: 135,
        angleCenter: { x: 0.5, y: 0.5 },
    },
    sideMenuWidth: 235,
    sideMenuSpeed: 450,
    menuSize: clamp(35, 50, 100 * scale),
}); };
// where our themes are defined
// these all accept a scale variable, which is used to scale the theme's styling to different screen sizes
// as such, they cannot be used without first providing scale
export var Themes = {
    dark: function (scale, smallerDimension) { return (__assign(__assign({}, defaultTheme(scale, smallerDimension)), { name: "dark", text: {
            color: "#FFFFFF",
        }, subscript: {
            fontSize: clamp(14, 16, 30 * scale),
            paddingTop: 10,
            color: "#AAAAAA",
        }, sideMenuColor: "#000000", background: ["#000000", "#000000", "#1a1a1a", "#3d3d3d"], navButton: {
            backgroundColor: "#DDDDDD",
            borderRadius: 999,
            width: clamp(35, 50, 100 * scale),
            height: clamp(35, 50, 100 * scale),
            marginLeft: clamp(35, 50, 100 * scale),
        }, menu: white_menu, showcaseDivider: {
            width: "50%",
            height: 2,
            backgroundColor: "#FFFFFF",
        }, linkBackground: ["#ffb0fb", "#19344d"], floatingText: {
            fontSize: clamp(20, 30, 45 * scale),
            color: "#FFFFFF",
            fontWeight: "bold",
        } })); },
    broken: function (scale, smallerDimension) { return ({
        name: "broken",
        text: {
            color: "#000000",
        },
        sideMenuColor: "#FFFFFF",
        background: ["#FFFFFF", "#FFFFFF", "#EEEEEE", "#CCCCCC"],
        navButton: {
            backgroundColor: "#333333",
            borderRadius: 0,
            width: clamp(70, 100, 200 * scale),
            height: clamp(35, 50, 100 * scale),
            marginLeft: clamp(0, 0, 0 * scale),
        },
        menu: black_menu,
        showcaseDivider: {
            width: "100%",
            height: 600,
            backgroundColor: "#000000",
        },
        linkBackground: ["#84ff0a", "#5a4c5c"],
        floatingText: {
            fontSize: clamp(20, 30, 45 * scale),
            color: "#000000",
            fontWeight: "bold",
        },
        header: {
            fontSize: clamp(20, 40, 100 * scale),
            fontWeight: "bold",
        },
        body: {
            fontSize: clamp(15, 30, 50 * scale),
        },
        caption: {
            fontSize: clamp(50, 75, 100 * scale),
        },
        subscript: {
            textDecorationLine: "underline",
            textDecorationStyle: "dotted",
            fontSize: 2
        },
        buttonText: {
            fontSize: clamp(7, 8, 15 * scale),
            paddingBottom: 2,
        },
        phoneHeight: clamp(1200, 1600, 2000 * scale),
        phoneScaleInitial: 1.5,
        phoneScaleFinal: 0.65,
        appScaleInitial: 1.5,
        appCycleTime: 0.5,
        largeSpace: clamp(150, 400, 500 * scale),
        mediumSpace: clamp(75, 200, 300 * scale),
        mediumSmallSpace: clamp(0, 0, 0 * scale),
        smallSpace: clamp(0, 0, 0 * scale),
        messageHeightHolder: clamp(50, 100, 150 * scale),
        screenAnimationY: clamp(-300, -100, -300 * scale),
        screenAnimationSpeed: 1500,
        screenAnimationOutSpeed: 1,
        showcaseImageShort: clamp(250, 400, 600 * scale),
        showcaseImageLong: clamp(125, 200, 300 * scale),
        showcaseTextWidth: clamp(150, 350, 500 * scale),
        appLinkSize: clamp(15, 250, 25 * (1 / scale)),
        webLinkWidth: clamp(30, 50, 75 * scale),
        webLinkHeight: clamp(60, 100, 150 * scale),
        linearGradient: {},
        sideMenuWidth: 75,
        sideMenuSpeed: 5000,
        menuSize: clamp(35, 50, 100 * scale),
    }); },
};
// set the initial context using the dark theme and starting window width
export var ThemeContext = createContext(Themes["dark"](Dimensions.get("window").width / 1984, "width"));
// setup context for changing the theme
var DEFAULT_VAL_FOR_TS = function (
// this is simply an empty theme state setter
setter) { };
export var SetThemeContext = React.createContext(DEFAULT_VAL_FOR_TS);
export var ThemeProvider = function (_a) {
    var children = _a.children;
    // scaling operations for different screen sizes
    // we normalize around 1984 and 1003 because those are my monitor's dimensions :P
    var window = Dimensions.get("window");
    var getThemeVariables = function (window) {
        var height = Math.pow(window.height / 1003, 1.2);
        var width = window.width / 1984;
        var scale = Math.min(height, width);
        var smallerDimension = height < width ? "height" : "width";
        return {
            scale: scale,
            smallerDimension: smallerDimension,
        };
    };
    var themeVariables = getThemeVariables(window);
    var _b = useState(themeVariables.scale), scale = _b[0], setScale = _b[1];
    var _c = useState(themeVariables.smallerDimension), smallerDimension = _c[0], setSmallerDimension = _c[1];
    // we handle the current theme state here so that we can also change it from any component via SetThemeContext
    var _d = useState(function () { return Themes["dark"]; }), curTheme = _d[0], setCurTheme = _d[1];
    // when the size of the window changes, update our scaling
    useEffect(function () {
        var unsub = Dimensions.addEventListener("change", function (_a) {
            var window = _a.window;
            var themeVariables = getThemeVariables(window);
            setScale(themeVariables.scale);
            setSmallerDimension(themeVariables.smallerDimension);
        });
        return unsub.remove;
    }, []);
    return (_jsx(SetThemeContext.Provider, __assign({ value: setCurTheme }, { children: _jsx(ThemeContext.Provider, __assign({ value: curTheme(scale, smallerDimension) }, { children: children })) })));
};
