var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useRef, useContext, useEffect } from "react";
import { Flex } from "../Components";
import { HomeScreenMessage } from "./HomeScreenMessage";
import { HomeScreenImages } from "./HomeScreenImages";
import { AnimatedScreen } from "./AnimatedScreen";
import { ThemeContext } from "../Theme";
import { isScreenSmall } from "../Helpers";
export var CAT_MODE_KEY = "laplantAppsCatMode 82jfnfoi239uf2jibn29yt928rth984h3ut9u923r";
export var SCREEN_SEEN_KEY = "laplantAppsScreenSeed 82jfnfoi239uf2jibn29yt928rth984h3ut9u923r";
// This is the landing page of the website
// It's split into two halves, one for a typed-out and changing message, the other for an animated phone showing previews of various apps
// On a small screen we only show one of these halves at once and alternate between the two each time the user comes to this screen/page
// It animates in and out using the usual AnimatedScreen
export var HomeScreen = function (_a) {
    var _b;
    var route = _a.route;
    var catMode = useRef(Boolean(localStorage.getItem(CAT_MODE_KEY)));
    // get the last-seen-screen from storage or starting one and get the next one to be seen by negating it
    var lastSeenScreen = useRef(localStorage.getItem(SCREEN_SEEN_KEY) || JSON.stringify(Math.random() > 0.5)).current;
    var nextScreen = useRef(!JSON.parse(lastSeenScreen)).current;
    useEffect(function () {
        // store last-seen screen
        localStorage.setItem(SCREEN_SEEN_KEY, JSON.stringify(nextScreen));
    }, []);
    var theme = useContext(ThemeContext);
    var space = theme.mediumSpace;
    var setCatMode = function (mode) {
        catMode.current = mode;
    };
    // we dont need a listener since the theme listens for us
    var smallScreen = isScreenSmall();
    // use min height to ensure the phone's final size is used for centering
    var minHeight = theme.phoneHeight * theme.phoneScaleFinal;
    var optionalStyles = {
        paddingHorizontal: smallScreen ? theme.mediumSmallSpace : undefined,
        paddingBottom: smallScreen && nextScreen ? theme.largeSpace : undefined,
    };
    // if we're on a small screen, only show 1 of the two halves
    return (_jsx(AnimatedScreen, __assign({ fadeOut: (_b = route === null || route === void 0 ? void 0 : route.params) === null || _b === void 0 ? void 0 : _b.fadeOut }, { children: _jsx(Flex, __assign({ full: true, centered: true, style: [{ minHeight: minHeight }, optionalStyles] }, { children: smallScreen ? (nextScreen ? (_jsx(HomeScreenMessage, { setCatMode: setCatMode, catMode: catMode.current })) : (_jsx(HomeScreenImages, { catMode: catMode, showTitle: true }))) : (_jsxs(Flex, __assign({ full: true, slim: true, row: true }, { children: [_jsx(Flex, __assign({ full: true, centeredVertical: true, style: {
                            paddingHorizontal: space,
                            marginTop: -space,
                        } }, { children: _jsx(HomeScreenMessage, { setCatMode: setCatMode, catMode: catMode.current }) })), _jsx(Flex, __assign({ full: true, centered: true, style: { paddingHorizontal: space } }, { children: _jsx(HomeScreenImages, { catMode: catMode }) }))] }))) })) })));
};
