var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { TouchableOpacity, Image } from "react-native";
import React, { useContext } from "react";
import { ThemeContext } from "../Theme";
import { Flex, Padding, SideMenu, StyledText, ThemeButtons, } from ".";
import { isScreenSmall } from "../Helpers";
// This component takes up the full width of the screen and is shown on every screen
// It is used to display the current page name (unless the user is on the home page), the menu button, and three empty buttons
export var ScreenHeader = function (_a) {
    var _b;
    var navigation = _a.navigation, route = _a.route, options = _a.options, back = _a.back;
    var theme = useContext(ThemeContext);
    // we maintain a sidemneu ref to toggle it open or closed
    var sideMenuRef = React.useRef(null);
    // we dont need to add a listener since our theme will rerender us
    var bigScreen = !isScreenSmall();
    return (_jsxs(Flex, __assign({ fullWidth: true, row: true, centeredVertical: true, style: {
            paddingHorizontal: theme.largeSpace,
            paddingVertical: theme.mediumSpace,
        } }, { children: [_jsx(SideMenu, { navigation: navigation, ref: sideMenuRef }), _jsx(TouchableOpacity, __assign({ onPress: (_b = sideMenuRef.current) === null || _b === void 0 ? void 0 : _b.toggleMenu }, { children: _jsx(Image, { source: theme.menu, style: { width: theme.menuSize, height: theme.menuSize } }) })), bigScreen && (_jsxs(_Fragment, { children: [_jsx(Padding, { horizontal: theme.mediumSpace }), _jsx(StyledText, __assign({ type: "caption" }, { children: "LaPlante Studios" })), _jsx(StyledText, __assign({ type: "caption", style: { marginLeft: "auto", marginRight: "auto" } }, { children: route.name === "Home" ? null : route.name }))] })), _jsx(ThemeButtons, {})] })));
};
