var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @ts-ignore-next-line
import { ScrollView } from "react-native";
import { AnimatedScreen } from "./AnimatedScreen";
import React, { useContext } from "react";
import { ShowcaseRow } from "../Components";
import beatbiome from "../assets/beatbiome.gif";
import sellsheet from "../assets/sellsheet.png";
import { ThemeContext } from "../Theme";
import site from "../assets/site.png";
import siteSmall from "../assets/siteSmall.png";
import { isScreenSmall } from "../Helpers";
// This is the screen that shows a list of non-app projects, like board games and VR games
// This shows in the same ShowcaseRows as AppScreen and WorkScreen, showing a title, description, image, and link(s)
// It animates in and out using the usual AnimatedScreen
export var NonAppsScreen = function (_a) {
    var _b;
    var route = _a.route;
    var theme = useContext(ThemeContext);
    var smallScreen = isScreenSmall();
    return (_jsx(AnimatedScreen, __assign({ fadeOut: (_b = route === null || route === void 0 ? void 0 : route.params) === null || _b === void 0 ? void 0 : _b.fadeOut }, { children: _jsxs(ScrollView, __assign({ style: { height: 1 } }, { children: [_jsx(ShowcaseRow, { title: "Beat Biome", description: "A virtual reality game that reacts to your music! Available on Steam", tech: "Unity, C#, SteamVR, Audio Manipulation", image: beatbiome, horizontalImage: true, sharpEdges: true, link: "https://store.steampowered.com/app/1506960/Beat_Biome/", isFirst: true }), _jsx(ShowcaseRow, { title: "LoreCraft", description: "A board game for 2-10 players, combining story telling, strategy, collaboration and more!", image: sellsheet, customImageDimensions: {
                        width: theme.showcaseImageLong * 2 / 3,
                        height: theme.showcaseImageLong,
                        left: theme.showcaseImageLong * 2 / 5,
                    }, sharpEdges: true, link: { path: "Boardgames" } }), _jsx(ShowcaseRow, { title: "LaPlante Studios Site", description: "This website!", tech: "RN Web, Typescript, Node, Webpack, Google Cloud", image: smallScreen ? siteSmall : site, horizontalImage: smallScreen ? false : true, sharpEdges: true, link: "/home" }), _jsx(ShowcaseRow, { title: "Misc AI", description: "Various AI projects, including a monte carlo simulation of millions of card games across multiple threads, anthropic categorization of files, open ai and puppeteer scraping webpages for information, and more! ", tech: "Monte Carlo, Puppeteer, Typescript, Node, Multithreading, Anthropic, OpenAI" })] })) })));
};
