var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { TouchableOpacity, Linking, Animated, Easing } from "react-native";
import React, { useRef, useEffect, useContext } from "react";
// @ts-ignore-next-line
import LinearGradient from "react-native-web-linear-gradient";
import apple from "../assets/apple.png";
import android from "../assets/android.png";
import { StyledText } from "./Text";
import { ThemeContext } from "../Theme";
import { useNavigation } from "@react-navigation/native";
import { FadeInImage } from "./FadeInImage";
// This button shows either an Apple icon, Android icon, or a pill button
// When it is pressed, it opens the link in a new tab
export var ShowcaseButton = function (_a) {
    var link = _a.link, name = _a.name, _b = _a.singleColumn, singleColumn = _b === void 0 ? false : _b;
    var navigation = useNavigation();
    var theme = useContext(ThemeContext);
    // When the user presses this showcase button
    var onPress = function () {
        // open a normal link in a new tab
        if (typeof link === "string") {
            Linking.openURL(link);
        }
        // if the link is a path within our own site, manually fade out and navigate
        else {
            // @ts-ignore-next-line
            navigation.setParams({ fadeOut: true });
            setTimeout(function () {
                // @ts-ignore-next-line
                navigation.push(link.path);
                // default screen animation time from SideMenu.tsx
            }, theme.sideMenuSpeed / 2);
        }
    };
    return (_jsxs(TouchableOpacity, __assign({ style: {
            marginRight: singleColumn ? undefined : theme.mediumSmallSpace,
            marginHorizontal: singleColumn ? theme.smallSpace : undefined,
            marginTop: theme.mediumSmallSpace,
        }, onPress: onPress }, { children: [name === "Apple" && _jsx(AppleIcon, {}), name === "Android" && _jsx(AndroidIcon, {}), name === "Link" && _jsx(PillButton, {})] })));
};
// This calls the given function after 5-30 seconds
var randomTimeout = function (f) {
    setTimeout(f, Math.random() * 25000 + 5000);
};
// The android icon slides from left to right and back, with appropriate acceleration/whip shown through rotation
var AndroidIcon = function () {
    var theme = useContext(ThemeContext);
    // use a central animation value to drive the icon's position and rotation using interpolation
    var androidAnimation = useRef(new Animated.Value(0)).current;
    var androidRotation = useRef(androidAnimation.interpolate({
        inputRange: [0, 10, 20, 50, 65, 75, 100, 107, 115],
        outputRange: [
            "0deg",
            "-10deg",
            "-20deg",
            "-20deg",
            "10deg",
            "20deg",
            "20deg",
            "-10deg",
            "0deg",
        ],
        extrapolate: "clamp",
    })).current;
    var androidLeft = useRef(androidAnimation.interpolate({
        inputRange: [0, 50, 55, 115],
        outputRange: [0, 144, 150, 0],
        extrapolate: "clamp",
    })).current;
    // animate the icon after 5-30 seconds
    useEffect(function () {
        randomTimeout(nextAnimationAndroid);
    }, []);
    var nextAnimationAndroid = function () {
        Animated.timing(androidAnimation, {
            toValue: 115,
            duration: 1000,
            useNativeDriver: false,
        }).start(function () {
            androidAnimation.setValue(0);
        });
        // animate the icon again after 5-30 seconds
        randomTimeout(nextAnimationAndroid);
    };
    return (_jsx(Animated.View, __assign({ style: {
            transform: [{ translateX: androidLeft }],
        } }, { children: _jsx(FadeInImage, { style: {
                height: theme.appLinkSize,
                width: theme.appLinkSize,
                transform: [{ rotate: androidRotation }],
            }, source: android }) })));
};
// The apple icon jumps up and back down, with appropriate deceleration/acceleration to show interesting gravity
var AppleIcon = function () {
    var theme = useContext(ThemeContext);
    var appleAnimation = useRef(new Animated.Value(0)).current;
    // animate the icon after 5-30 seconds
    useEffect(function () {
        randomTimeout(nextAnimationApple);
    }, []);
    var nextAnimationApple = function () {
        Animated.sequence([
            // go up, decelerating
            Animated.timing(appleAnimation, {
                toValue: -100,
                duration: 500,
                easing: Easing.out(Easing.sin),
                useNativeDriver: false,
            }),
            // go down, accelerating
            Animated.timing(appleAnimation, {
                toValue: 0,
                duration: 750,
                easing: Easing.bounce,
                useNativeDriver: false,
            }),
        ]).start();
        // animate the icon after 5-30 seconds
        randomTimeout(nextAnimationApple);
    };
    return (_jsx(FadeInImage, { style: {
            height: theme.appLinkSize,
            width: theme.appLinkSize,
            transform: [{ translateY: appleAnimation }],
        }, source: apple }));
};
// The pill button bulges, curves/sharpens, and twists to show interesting motion
// It then returns to its original state
var PillButton = function () {
    var theme = useContext(ThemeContext);
    // use a central animation value to drive the button's scale, radius, and rotation using interpolation
    var linkAnimation = useRef(new Animated.Value(0)).current;
    // bulge vertically
    var linkScaleY = useRef(linkAnimation.interpolate({
        inputRange: [0, 20, 40, 60, 80, 100],
        outputRange: [1, 1.2, 1.5, 1.2, 1.8, 1],
        extrapolate: "clamp",
    })).current;
    // bulge horizontally
    var linkScaleX = useRef(linkAnimation.interpolate({
        inputRange: [0, 20, 40, 60, 80, 100],
        outputRange: [1, 0.75, 1, 1.25, 1.5, 1],
        extrapolate: "clamp",
    })).current;
    // curve/sharpen
    var linkRadius = useRef(linkAnimation.interpolate({
        inputRange: [0, 20, 40, 60, 80, 100],
        outputRange: [35, 10, 20, 50, 20, 35],
        extrapolate: "clamp",
    })).current;
    // twist
    var linkRotation = useRef(linkAnimation.interpolate({
        inputRange: [0, 20, 40, 60, 80, 100],
        outputRange: ["0deg", "-10deg", "10deg", "-20deg", "30deg", "0deg"],
        extrapolate: "clamp",
    })).current;
    // keep the link text upright
    var linkCounterRotate = useRef(linkAnimation.interpolate({
        inputRange: [0, 20, 40, 60, 80, 100],
        outputRange: ["0deg", "10deg", "-10deg", "20deg", "-30deg", "0deg"],
        extrapolate: "clamp",
    })).current;
    // animate the icon after 5-30 seconds
    useEffect(function () {
        randomTimeout(nextAnimationLink);
    }, []);
    var nextAnimationLink = function () {
        Animated.timing(linkAnimation, {
            toValue: 100,
            duration: 1000,
            useNativeDriver: false,
        }).start(function () {
            linkAnimation.setValue(0);
        });
        // animate the icon after 5-30 seconds
        randomTimeout(nextAnimationLink);
    };
    return (_jsx(Animated.View, __assign({ style: {
            borderRadius: linkRadius,
            overflow: "hidden",
            height: theme.webLinkHeight,
            width: theme.webLinkWidth,
            transform: [
                { scaleY: linkScaleY },
                { scaleX: linkScaleX },
                { rotate: linkRotation },
            ],
        } }, { children: _jsx(LinearGradient, __assign({ colors: theme.linkBackground }, theme.linearGradient, { style: {
                height: "100%",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
            } }, { children: _jsx(StyledText, __assign({ animated: true, style: {
                    transform: [
                        { scaleY: Animated.divide(new Animated.Value(1), linkScaleY) },
                        { scaleX: Animated.divide(new Animated.Value(1), linkScaleX) },
                        { rotate: linkCounterRotate },
                    ],
                }, type: "button" }, { children: "Link" })) })) })));
};
