var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Animated, View } from "react-native";
import React, { useState, useEffect, useRef, useContext, } from "react";
import { easeOutBack } from "../Components";
import phone_case from "../assets/phone_case.png";
import phone_inner from "../assets/phone_inner.png";
import { ThemeContext } from "../Theme";
// This component animates a phone cycling through a list of images/gifs
// You'll see constants used in various equations below. These are relative sizes to ensure that all the images appear in the correct proportions
export var SlideshowPhone = function (_a) {
    var pictureLists = _a.pictureLists, curListRef = _a.curListRef, onFirstCycleComplete = _a.onFirstCycleComplete;
    // we need to convert our boxed boolean into a boxed number
    var curListNum = function () { return (curListRef.current ? 1 : 0); };
    var theme = useContext(ThemeContext);
    var prevListRef = useRef(curListNum());
    var _b = useState(false), phoneCycling = _b[0], setPhoneCycling = _b[1];
    var finalPhoneScale = theme.phoneScaleFinal;
    var basePhoneHeight = theme.phoneHeight;
    var defaultPhoneHeight = 1232;
    var phoneHeightRatio = basePhoneHeight / defaultPhoneHeight;
    var basePhoneWidth = 572 * phoneHeightRatio;
    var heightPhoneAtScale = basePhoneHeight * finalPhoneScale;
    var widthPhoneAtScale = basePhoneWidth * finalPhoneScale;
    var heightAppAtScale = 1050 * phoneHeightRatio * finalPhoneScale;
    var widthAppAtScale = (520 / 572) * basePhoneWidth * finalPhoneScale;
    // we use 2 app screens, one on top of the other, so we can animate the new one in and the old one out, then they swap roles
    var curApp = useRef(0);
    var _c = useState(0), curPicOneState = _c[0], setCurPicOneSetter = _c[1];
    var _d = useState(1), curPicTwoState = _d[0], setCurPicTwoSetter = _d[1];
    var innerPhoneOpacity = useRef(new Animated.Value(1)).current;
    var startingTopVal = 250 * phoneHeightRatio;
    var startingScaleVal = theme.appScaleInitial;
    var appScreens = [
        {
            picList: useRef(pictureLists[curListNum()]),
            picOpacity: useRef(new Animated.Value(0)).current,
            picTop: useRef(new Animated.Value(startingTopVal)).current,
            picZ: useRef(new Animated.Value(1)).current,
            picScale: useRef(new Animated.Value(startingScaleVal)).current,
            curPic: curPicOneState,
            setCurPic: setCurPicOneSetter,
        },
        {
            picList: useRef(pictureLists[curListNum()]),
            picOpacity: useRef(new Animated.Value(0)).current,
            picTop: useRef(new Animated.Value(startingTopVal)).current,
            picZ: useRef(new Animated.Value(2)).current,
            picScale: useRef(new Animated.Value(startingScaleVal)).current,
            curPic: curPicTwoState,
            setCurPic: setCurPicTwoSetter,
        },
    ];
    // cycle through our apps
    useEffect(function () {
        var curAppScreen = appScreens[curApp.current];
        var nextAppScreen = appScreens[1 - curApp.current];
        // optionally fade out the inner phone if we just started cycling
        var optionalAnimation = phoneCycling
            ? []
            : [
                Animated.timing(innerPhoneOpacity, {
                    toValue: 0,
                    duration: 600,
                    useNativeDriver: false,
                }),
            ];
        Animated.parallel(__spreadArray(__spreadArray([], optionalAnimation, true), [
            // fade out old image
            Animated.timing(curAppScreen.picOpacity, {
                toValue: 0,
                duration: 600,
                useNativeDriver: false,
            }),
            // fade in new image
            Animated.timing(nextAppScreen.picOpacity, {
                toValue: 1,
                duration: 600,
                useNativeDriver: false,
            }),
            // slide new image into position
            Animated.timing(nextAppScreen.picTop, {
                toValue: 0,
                easing: easeOutBack,
                duration: 600,
                useNativeDriver: false,
            }),
            // scale up new image to full size
            Animated.timing(nextAppScreen.picScale, {
                toValue: 1,
                duration: 600,
                useNativeDriver: false,
            }),
        ], false)).start(function () {
            // now set them up to do it again next time
            setPhoneCycling(true);
            onFirstCycleComplete();
            setTimeout(function () {
                nextAppScreen.picZ.setValue(1);
                curAppScreen.picZ.setValue(2);
                curAppScreen.picScale.setValue(startingScaleVal);
                curAppScreen.picTop.setValue(startingTopVal);
            }, 500);
        });
        setTimeout(function () {
            // we switch over the picture lists here to ensure it doesnt conflict with the animation
            if (curListNum() !== prevListRef.current) {
                curAppScreen.picList.current = pictureLists[curListNum()];
            }
            // go to the next picture (by 2 so the 2 app screens leap frog each other)
            // if we go off the end of the list, reset to our original index
            if (curAppScreen.curPic + 2 > curAppScreen.picList.current.length - 1) {
                curAppScreen.setCurPic(curApp.current);
            }
            else {
                curAppScreen.setCurPic(curAppScreen.curPic + 2);
            }
            curApp.current = 1 - curApp.current;
            // every X seconds change the picture/gif
        }, theme.appCycleTime * 1000);
    }, [curPicOneState, curPicTwoState]);
    // render the phone and the apps
    return (_jsxs(View, __assign({ style: {
            overflow: "hidden",
            zIndex: 1,
            borderRadius: 51 * phoneHeightRatio,
            width: widthPhoneAtScale,
            height: heightPhoneAtScale,
        } }, { children: [_jsx(Animated.Image, { style: {
                    width: widthPhoneAtScale,
                    zIndex: 3,
                    height: heightPhoneAtScale,
                }, source: phone_case }), !phoneCycling && (_jsx(Animated.Image, { style: {
                    position: "absolute",
                    width: widthPhoneAtScale,
                    zIndex: 0,
                    opacity: innerPhoneOpacity,
                    height: heightPhoneAtScale,
                }, source: phone_inner })), _jsx(Animated.Image, { style: {
                    position: "absolute",
                    width: widthAppAtScale,
                    height: heightAppAtScale,
                    zIndex: appScreens[0].picZ,
                    top: 40 * phoneHeightRatio,
                    left: 15 * phoneHeightRatio,
                    opacity: appScreens[0].picOpacity,
                    transform: [
                        { translateY: appScreens[0].picTop },
                        { scale: appScreens[0].picScale },
                    ],
                }, source: appScreens[0].picList.current[appScreens[0].curPic] }), _jsx(Animated.Image, { style: {
                    position: "absolute",
                    width: widthAppAtScale,
                    height: heightAppAtScale,
                    zIndex: appScreens[1].picZ,
                    top: 40 * phoneHeightRatio,
                    left: 15 * phoneHeightRatio,
                    opacity: appScreens[1].picOpacity,
                    transform: [
                        { translateY: appScreens[1].picTop },
                        { scale: appScreens[1].picScale },
                    ],
                }, source: appScreens[1].picList.current[appScreens[1].curPic] })] })));
};
