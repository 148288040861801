var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState, useEffect, useRef, useContext, } from "react";
import { Animated, Image } from "react-native";
import { PlayfulPhone, SlideshowPhone, StyledText } from "../Components";
import iadventure from "../assets/iadventure.gif";
import npcg from "../assets/npcg_short.gif";
import dice from "../assets/dice.gif";
import virta from "../assets/virta_short.gif";
import weread from "../assets/weread_short.gif";
import hearyouout from "../assets/hearyouout_short.gif";
import sushimonster from "../assets/sushimonster_short.gif";
import cat1 from "../assets/cat1.png";
import cat2 from "../assets/cat2.png";
import cat3 from "../assets/cat3.png";
import cat4 from "../assets/cat4.png";
import cat5 from "../assets/cat5.png";
import cat6 from "../assets/cat6.png";
import cat7 from "../assets/cat7.png";
import cat8 from "../assets/cat8.png";
import cat9 from "../assets/cat9.png";
import cat10 from "../assets/cat10.png";
import cat11 from "../assets/cat11.png";
import cat12 from "../assets/cat12.png";
import cat13 from "../assets/cat13.png";
import cat14 from "../assets/cat14.png";
import cat15 from "../assets/cat15.png";
import { ThemeContext } from "../Theme";
// This component is responsible for animating in the phone (PlayfulPhone half) and then cycling through
// the various gifs or images (SlideshowPhone half). It does the handoff using two different state variables to assure no flickering
// It accepts a ref to determine which list of assets to use
// If catmode is enabled (1), it'll show cat pictures. Otherwise it'll show app gifs
export var HomeScreenImages = function (_a) {
    var catMode = _a.catMode, _b = _a.showTitle, showTitle = _b === void 0 ? false : _b;
    var appPictures = useRef([
        dice,
        hearyouout,
        virta,
        sushimonster,
        iadventure,
        weread,
        npcg,
    ]).current;
    var catPictures = useRef([
        cat14,
        cat15,
        cat1,
        cat2,
        cat3,
        cat4,
        cat5,
        cat6,
        cat7,
        cat8,
        cat9,
        cat10,
        cat11,
        cat12,
        cat13,
    ]).current;
    var _c = useState(false), phoneDone = _c[0], setPhoneDone = _c[1];
    var _d = useState(false), phoneCycling = _d[0], setPhoneCycling = _d[1];
    var opacityRef = useRef(new Animated.Value(1)).current;
    var theme = useContext(ThemeContext);
    useEffect(function () {
        // prefetch all images
        var catFetch = function () {
            catPictures.forEach(function (image) {
                Image.prefetch(image);
            });
        };
        if (catMode.current) {
            // if we're starting in cat mode, we only need the cats
            catFetch();
        }
        else {
            // otherwise, grab the apps first, then the cats afterwards
            var promises = appPictures.map(function (image) {
                return Image.prefetch(image);
            });
            Promise.all(promises).then(function () {
                catFetch();
            });
        }
        // make the title disappear right as the phone moves over it
        // on small screens only
        setTimeout(function () {
            Animated.timing(opacityRef, {
                toValue: 0,
                duration: 1,
                useNativeDriver: false,
            }).start();
        }, 7600);
    }, []);
    return (_jsxs(_Fragment, { children: [showTitle && (_jsx(StyledText, __assign({ animated: true, style: {
                    position: "absolute",
                    opacity: opacityRef,
                    paddingBottom: theme.largeSpace,
                }, type: "header" }, { children: catMode.current ? "LaCat Studios :3" : "LaPlante Studios" }))), !phoneCycling && (_jsx(PlayfulPhone, { onAnimationComplete: function () { return setPhoneDone(true); }, fast: showTitle })), phoneDone && (_jsx(SlideshowPhone, { pictureLists: [appPictures, catPictures], curListRef: catMode, onFirstCycleComplete: function () { return setPhoneCycling(true); } }))] }));
};
